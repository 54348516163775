<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="title-wrapper">
        <h1>用户信息</h1>
        <div class="title-cns">
          <div style="display: flex; justify-content: space-between">
            <div class="title-cn">用户名：{{ $route.query.loginName }}</div>
            <div class="title-cn">所属机构：{{ $route.query.agencyName }}</div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 12px;
            "
          >
            <div class="title-cn">手机号：{{ $route.query.phonenumber }}</div>
            <div class="title-cn">管理机构：{{ $route.query.parentName }}</div>
          </div>
        </div>
        <!-- <h1>{{ $route.query.agencyName }}</h1> -->
      </div>
      <div>
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'6'"
          @search="searchFn"
          :isResetByPublic="false"
          @clearSearch="clearSearchFn"
        >
          <search-item default label="">
            <el-cascader
              style="width: 100%"
              v-model="searchParams.searchAgencyCode"
              :options="orgTreeData"
              placeholder="请选择机构"
              clearable
              filterable
              :props="{
                expandTrigger: 'hover',
                label: 'agencyName',
                value: 'agencyCode',
                checkStrictly: true,
              }"
            ></el-cascader>
          </search-item>
          <search-item default label="">
            <el-select
              v-model="searchParams.monitorSubject"
              placeholder="监测主体"
              clearable
            >
              <el-option
                v-for="item in monitorSubjects"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item default label>
            <el-select
              v-model="searchParams.orderType"
              placeholder="订单类型"
              clearable
            >
              <el-option
                v-for="item in orderTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </search-item>
          <search-item default label="">
            <el-input
              size="small"
              placeholder="订单号"
              v-model="searchParams.orderCode"
              clearable
            />
          </search-item>
        </table-search>
      </div>
      <div class="bd-content">
        <!-- 操作按钮 -->
        <div class="table-opt">
          <el-button type="primary" @click="changeStateAll('2', selectData)"
            >批量管理</el-button
          >
          <el-button type="primary" @click="changeStateAll('1', selectData)"
            >批量浏览</el-button
          >
          <el-button type="primary" @click="changeStateAll('0', selectData)"
            >批量取消</el-button
          >
        </div>
        <pageTable
          ref="table"
          :columns="columnList"
          :params="searchParams"
          :initFalse="true"
          :api-function="$api.sysManage.systemFindAgencyUser"
          @selectAll="selectAll"
          @select="select"
        >
          <template slot="options" slot-scope="scope">
            <el-switch
              v-model="scope.row.managePermission"
              active-color="#13ce66"
              active-value="2"
              inactive-value="0"
              @change="changeState($event, scope.row)"
            >
            </el-switch>
          </template>
          <template slot="options1" slot-scope="scope">
            <el-switch
              v-model="scope.row.browsePermission"
              active-color="#13ce66"
              active-value="1"
              inactive-value="0"
              @change="changeState($event, scope.row)"
            >
            </el-switch>
          </template>
        </pageTable>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@/utils/public';
export default {
  name: 'userMember',
  data() {
    return {
      orderScanTypes: [
        { name: '算法', type: '1' },
        { name: '词库', type: '2' },
        { name: '人工', type: '3' },
      ], //产品名称下拉
      monitorSubjects: [
        { name: '网站', type: '0' },
        { name: '微信', type: '1' },
      ],
      searchParams: {
        searchAgencyCode: '',
        agencyCode: '',
        userCode: '',
        monitorSubject: '',
        orderCode: '',
        orderType: '', //订单类型
      },
      orgTreeData: [], //机构
      orderTypeList: [
        { label: '正式订单', value: '2000' },
        { label: '试用订单', value: '1000' },
      ],
      selectData: [],
      columnList: [
        {
          type: 'selection',
        },
        {
          label: '机构名称',
          prop: 'sitesName',
        },
        {
          label: '订单类型',
          prop: 'orderTypeDesc',
        },
        {
          label: '监测主体',
          prop: 'monitorSubjectTrans',
        },
        {
          label: '订单状态',
          prop: 'statusDesc',
        },
        {
          label: '订单号',
          prop: 'orderCode',
        },
        {
          label: '下单时间',
          prop: 'orderCreateTime',
        },
        {
          label: '管理',
          prop: 'options',
          show: 'template',
        },
        {
          label: '浏览',
          prop: 'options1',
          show: 'template',
        },
      ],
    };
  },
  // activated() {
  //   this.searchFn();
  // },
  created() {
    this.searchParams.agencyCode = this.$route.query.agencyCode;
    this.searchParams.searchAgencyCode = this.$route.query.agencyCode;
    this.searchParams.userCode = this.$route.query.userCode;
    this.searchFn();
  },
  mounted() {
    this.loadOrgTree();
  },
  methods: {
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    clearSearchFn() {
      this.searchParams = {
        searchAgencyCode: this.$route.query.agencyCode,
        agencyCode: this.$route.query.agencyCode,
        userCode: this.$route.query.userCode,
        monitorSubject: '',
        orderCode: '',
        orderType: '', //订单类型
      };
      this.$nextTick(() => {
        this.searchFn();
      });
    },
    searchFn: throttle(function () {
      this.$nextTick(() => {
        if (Array.isArray(this.searchParams.searchAgencyCode)) {
          this.searchParams.searchAgencyCode =
            this.searchParams.searchAgencyCode[
              this.searchParams.searchAgencyCode.length - 1
            ];
        }
        if (this.searchParams.searchAgencyCode === undefined) {
          this.searchParams.searchAgencyCode = '';
        }
        this.$refs.table.getPageDataOfAction('edit');
      });
    }, 10),
    //保存和取消保存
    changeState(callback, row) {
      // 传参处理
      var params = [
        {
          permissions: callback, //用户订单权限 1 浏览权限 2 管理权限
          orderCode: row.orderCode, //订单编号
          type: '1', //类型 1 普通用户 2 运营人员
          userCode: this.searchParams.userCode, //用户编码
        },
      ];
      this.$api.sysManage
        .addOrRemovoUserAll(params)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(callback == '0' ? '关闭成功' : '保存成功');
            this.searchFn();
          }
        })
        .catch((res) => {
          this.$message.error('操作失败');
          this.searchFn();
        });
    },
    // 分配
    changeStateAll(permissions, data) {
      if (this.selectData.length === 0) {
        this.$message.warning('请先勾选数据');
        return;
      }
      let promptDes = '';
      let isAll;
      if (permissions === '2') {
        isAll = data.every((item) => item.browsePermission === '0');
        if (isAll) {
          promptDes = '确定要批量管理权限吗？';
        } else {
          promptDes =
            '选择的数据中存在权限为浏览的数据，是否全部更新为管理的权限？';
        }
      } else if (permissions === '1') {
        isAll = data.every((item) => item.managePermission === '0');
        if (isAll) {
          promptDes = '确定要批量浏览权限吗？';
        } else {
          promptDes =
            '选择的数据中存在权限为管理的数据，是否全部更新为浏览的权限？';
        }
      }
      this.$msg
        .confirm(permissions === '0' ? '确认批量取消权限吗？' : promptDes)
        .then(() => {
          const newArr = data.map((item) => ({
            permissions: permissions, //用户订单权限 1 浏览权限 2 管理权限
            orderCode: item.orderCode, //订单编号
            type: '1', //类型 1 普通用户 2 运营人员
            userCode: this.searchParams.userCode, //用户编码
          }));
          this.$api.sysManage.addOrRemovoUserAll(newArr).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: permissions === '0' ? '批量取消成功' : '批量保存成功',
                type: 'success',
              });
              this.selectData = [];
              this.searchFn();
            }
          });
        });
    },
    //加载机构树
    async loadOrgTree() {
      let obj = {
        agencyCode: this.$route.query.agencyCode, //机构编码，pageSource为1时必填
        userCode: this.$route.query.userCode, //用户编码，pageSource为1时必填
      };
      let res = await this.$api.sysManage.agencyTreeByOrder(obj);
      if (res.data) {
        this.orgTreeData = res.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  background: #fff;
  .pt-wrapper {
    padding: 15px;
    .title-wrapper {
      margin-bottom: 10px;
    }
    h1 {
      font-size: 16px;
    }
    .search-wrap {
      margin: 10px 0px;
    }
  }
  .title-cns {
    width: 100%;
    background-color: #e4e7ed;
    padding: 0.5rem;
    margin-top: 10px;
  }
}

.title-cn {
  width: 50%;
  // font-size: 14px;
}
</style>
